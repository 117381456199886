import { Theme as MuiTheme } from '@mui/material/styles';

export enum PressedKey {
  Space = 'Space',
  Escape = 'Escape',
  Enter = 'Enter',
  Backspace = 'Backspace',
}

// TODO: make styled components expect theme as a property instead of using type casting
export type Theme = MuiTheme

export enum ThemeMode {
  Light = 'light',
  Dark = 'dark',
}

export enum EntityType {
  Note = 'note',
  Repository = 'repository',
  RepositoryEntry = 'repositoryEntry',
  PropertyDefinitionBoolean = 'templatePropertyBoolean',
  PropertyDefinitionText = 'templatePropertyText',

  PropertyDefinitionString = 'templatePropertyString',
  PropertyDefinitionSelectString = 'templatePropertySelectString',
  PropertyDefinitionSelectStringOption = 'templatePropertySelectStringOption',
  PropertyDefinitionBelongsTo = 'templatePropertyBelongsTo',
  // PropertyDefinitionSelectBelongsTo = 'templatePropertySelectBelongsTo',
  // PropertyDefinitionSelectBelongsToOption = 'templatePropertySelectBelongsToOption',
  PropertyDefinitionHas = 'templatePropertyHas',

  EntryPropertyString = 'entryPropertyString',
  EntryPropertyBoolean = 'entryPropertyBoolean',
  // EntryPropertyText = 'entryPropertyText',
  EntryPropertyBelongsTo = 'entryPropertyBelongsTo',
}

interface NoteModel {
  id: string,
  fakeId?: string,
  companyId?: string,
  parentId: string | null,
  title: string,
  description: string,
}

interface RepositoryModel {
  id: string,
  fakeId?: string,
  companyId?: string,
  parentId: string | null,
  title: string,
  singular: string | null,
}

interface PropertyDefinitionSelectStringModel {
  id: string,
  fakeId?: string,
  companyId?: string,
  parentId: string,
  label: string,
  slug: string,
  required?: boolean,
}

interface PropertyDefinitionSelectStringOptionModel {
  id: string,
  fakeId?: string,
  companyId?: string,
  parentId: string,
  label: string,
  value: string,
}

interface PropertyDefinitionStringModel {
  id: string,
  fakeId?: string,
  companyId?: string,
  parentId: string,
  label: string,
  slug: string,
  required?: boolean,
  disabled?: boolean,
  autoFocus?: boolean,
  placeholder?: string,
}

interface PropertyDefinitionBooleanModel {
  id: string,
  fakeId?: string,
  companyId?: string,
  parentId: string,
  label: string,
  slug: string,
  required?: boolean,
  defaultChecked?: boolean,
}

interface PropertyDefinitionTextModel {
  id: string,
  fakeId?: string,
  companyId?: string,
  parentId: string,
  label: string,
  slug: string,
  required: boolean,
}

interface PropertyDefinitionBelongsToModel {
  id: string,
  fakeId?: string,
  companyId?: string,
  parentId: string,
  label: string,
  slug: string,
  targetRepositoryId: string,
  required: boolean,
  allowMany: boolean,
}

interface PropertyDefinitionHasModel {
  id: string,
  fakeId?: string,
  companyId?: string,
  parentId: string,
  label: string,
  slug: string,
  targetRepositoryId: string,
  targetPropertySlug: string,
  required: boolean,
}

interface RepositoryEntryModel {
  id: string,
  fakeId?: string,
  companyId?: string,
  parentId: string,
  repositoryId: string,
  isArchived?: boolean,
}

interface EntryPropertyStringModel {
  id: string,
  fakeId?: string,
  companyId?: string,
  parentId: string,
  repositoryId: string,
  propertyDefinitionId: string,
  slug: string,
  value: string,
  isArchived?: boolean,
}

interface EntryPropertyBooleanModel {
  id: string,
  fakeId?: string,
  companyId?: string,
  parentId: string,
  repositoryId: string,
  propertyDefinitionId: string,
  slug: string,
  value: boolean,
  isArchived?: boolean,
}

interface EntryPropertyBelongsToModel {
  id: string,
  fakeId?: string,
  companyId?: string,
  parentId: string,
  repositoryId: string,
  propertyDefinitionId: string,
  targetRepositoryId: string,
  slug: string,
  value: string,
  isArchived?: boolean,
}

interface ModelPropsMap {
  [EntityType.Note]: NoteModel
  [EntityType.Repository]: RepositoryModel,
  [EntityType.RepositoryEntry]: RepositoryEntryModel,
  [EntityType.PropertyDefinitionString]: PropertyDefinitionStringModel,
  [EntityType.PropertyDefinitionBoolean]: PropertyDefinitionBooleanModel,
  [EntityType.PropertyDefinitionText]: PropertyDefinitionTextModel,
  [EntityType.PropertyDefinitionBelongsTo]: PropertyDefinitionBelongsToModel,
  [EntityType.PropertyDefinitionHas]: PropertyDefinitionHasModel,
  [EntityType.PropertyDefinitionSelectString]: PropertyDefinitionSelectStringModel,
  [EntityType.PropertyDefinitionSelectStringOption]: PropertyDefinitionSelectStringOptionModel,
  [EntityType.EntryPropertyString]: EntryPropertyStringModel,
  [EntityType.EntryPropertyBoolean]: EntryPropertyBooleanModel,
  [EntityType.EntryPropertyBelongsTo]: EntryPropertyBelongsToModel
}

interface TemplateModelPropsMap {
  [EntityType.PropertyDefinitionString]: PropertyDefinitionStringModel,
  [EntityType.PropertyDefinitionBoolean]: PropertyDefinitionBooleanModel,
  [EntityType.PropertyDefinitionText]: PropertyDefinitionTextModel,
  [EntityType.PropertyDefinitionBelongsTo]: PropertyDefinitionBelongsToModel,
  [EntityType.PropertyDefinitionHas]: PropertyDefinitionHasModel,
  [EntityType.PropertyDefinitionSelectString]: PropertyDefinitionSelectStringModel,
}

type TemplateProps<T extends keyof TemplateModelPropsMap> = Omit<TemplateModelPropsMap[T] & { type: T }, 'id' | 'parentId'>;

type EntryBaseProps<T extends keyof ModelPropsMap> = ModelPropsMap[T] & { type: T }

export interface EntryUIProps {
  rank: number,
}

export type EntryProps<T extends keyof ModelPropsMap> = EntryBaseProps<T> & EntryUIProps;

type ModelTemplatesMap = {
  [K in keyof TemplateModelPropsMap]: TemplateProps<K>
};

type EntriesMap = {
  [K in keyof ModelPropsMap]: EntryProps<K>
};

export type ModelTemplate = ModelTemplatesMap[keyof ModelTemplatesMap];

export type Entry = EntriesMap[keyof EntriesMap];

export type NoteEntry = EntryProps<EntityType.Note>;

export type AnyPropertyDefinitionEntityType = EntityType.PropertyDefinitionString | EntityType.PropertyDefinitionBoolean | EntityType.PropertyDefinitionText | EntityType.PropertyDefinitionBelongsTo | EntityType.PropertyDefinitionHas;

export type AnyEntryPropertyEntityType = EntityType.EntryPropertyString | EntityType.EntryPropertyBoolean | EntityType.EntryPropertyBelongsTo;

export type PropertyDefinitionStringEntry = EntryBaseProps<EntityType.PropertyDefinitionString>;

export type PropertyDefinitionSelectStringOptionEntry = EntryBaseProps<EntityType.PropertyDefinitionSelectStringOption>;

export type Repository = EntryBaseProps<EntityType.Repository>;

export type RepositoryEntry = EntryProps<EntityType.RepositoryEntry>;

export type PropertyDefinition = EntryBaseProps<AnyPropertyDefinitionEntityType>;

export type PropertyDefinitionBoolean = EntryBaseProps<EntityType.PropertyDefinitionBoolean>;

export type PropertyDefinitionBelongsTo = EntryBaseProps<EntityType.PropertyDefinitionBelongsTo>;

export type PropertyDefinitionHas = EntryBaseProps<EntityType.PropertyDefinitionHas>;

export type EntryProperty = EntryBaseProps<AnyEntryPropertyEntityType>;

export type EntryPropertyString = EntryBaseProps<EntityType.EntryPropertyString>;

export type EntryPropertyBelongsTo = EntryBaseProps<EntityType.EntryPropertyBelongsTo>;

export interface SelectOption {
  value: string,
  label: string,
}

export interface BelongsToPopulatedValue {
  id: string, // belongsTo record id
  value: string, // target entry id
  label: string, // target entry label
}

export type PopulatedEntryProperty = string | boolean | BelongsToPopulatedValue[];

export type RepositoryEntryData = {
  [key: string]: PopulatedEntryProperty;
}

export type PopulatedEntry = RepositoryEntry & {
  data: RepositoryEntryData,
  label: string;
}

export type FormSelectOptions = Record<string, SelectOption[]>;

export type FormData = {
  [key: string]: any;
} & {
  rank?: number;
} & {
  type?: EntityType;
}

export enum RepositoryViewTab {
  Entries = 'Entries',
  Template = 'Template',
}
