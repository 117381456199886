import React, {useMemo, useRef} from 'react';
import {Box, Button, Container, useMediaQuery} from '@mui/material';
import {Entry, Repository, Theme} from '../types';
import EntriesTable from '../components/EntriesTable';
import AddRepositoryEntryForm, {
	AddRepositoryEntryFormDialog,
	useAddRepositoryEntryForm
} from '../components/AddRepositoryEntryForm';
import {useAppDispatch, useAppSelector} from '../hooks';
import {showArchivedEntriesToggle, selectHideArchivedEntries, selectRepositoryEntriesPopulated, selectRepositoryPropertyDefinitions} from './repositoryViewSlice';
import {useContentNavigationEffects} from '../components/ContentNavigationEffects';
import {useRepositoryKeyboardEffects} from '../components/KeyboardEffects';
import ConfirmDeleteEntryDialog, {useConfirmDeleteEntryDialog} from '../components/ConfirmDeleteEntryDialog';
import EditEntryForm, {EditFormDialog, useEditForm} from '../components/EditEntryForm';
import { RowMenuItem, RowMenuOption, RowMenuOptionArchive, RowMenuOptionDelete, RowMenuOptionEdit, RowMenuOptionEffect, RowMenuOptionGoTo, RowMenuOptionMoveToTop, RowMenuOptionUnarchive } from '../components/EntryMenu';
import useNavigateToLocationId from '../components/NavigateToLocationId';
import {getRepositorySingleUnitLabel} from '../common/util';
import {useAddEntryForm} from '../components/AddEntryForm';
import { useRepositoryEntryArchiveMutation, useRepositoryEntryUnarchiveMutation } from '../services/repositoryEntries';
import { selectIsAuthenticated } from './authSlice';
import { entriesUpdateMany } from './contentSlice';

interface Props {
  repository: Repository;
}

const RepositoryViewEntries = ({repository}: Props) => {
	useContentNavigationEffects();
	useRepositoryKeyboardEffects();

	const repositoryPropertyDefinitions = useAppSelector(selectRepositoryPropertyDefinitions);
	const allEntries = useAppSelector(selectRepositoryEntriesPopulated);

	const showArchivedEntries = useAppSelector(selectHideArchivedEntries);
	const populatedEntries = allEntries.filter((entry) => showArchivedEntries ? !entry.isArchived : entry.isArchived);

	const {isAddDialogOpen, closeAddDialog} = useAddEntryForm();
	const {handleAddDialogSubmit, handleAddDialogValueChange} = useAddRepositoryEntryForm();
	const {isEditDialogOpen, editFormEntry, openEditDialog, closeEditDialog, onEditFormSubmitEntry} = useEditForm();
	const {isConfirmDeleteDialogOpen, confirmDeleteDialogEntry, openConfirmDeleteDialog, closeConfirmDeleteDialog, submitConfirmDeleteDialog} = useConfirmDeleteEntryDialog();
	const navigateToLocationId = useNavigateToLocationId();
	const [entryArchiveMutation] = useRepositoryEntryArchiveMutation();
	const [entryUnarchiveMutation] = useRepositoryEntryUnarchiveMutation();
	const isAuthenticated = useAppSelector(selectIsAuthenticated);
	const isSmScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const dispatch = useAppDispatch();

	const menuAnchorEl = useRef<Element | null>(null);
	const [menuEntry, setMenuEntry] = React.useState<null | Entry>(null);

	const handleRowMenuBtnClick = (item: RowMenuItem, el?: Element) => {
		setMenuEntry(item as Entry);
		if (el) {
			menuAnchorEl.current = el;
		}
	};

	const closeTableRowMenu = () => setMenuEntry(null);

	const handleTableRowMenuDelete = (id: string) => {
		closeTableRowMenu();
		const entry = populatedEntries.find((entry) => entry.id === id);
		if (entry) {
			openConfirmDeleteDialog(entry as unknown as Entry);
		}
	};

	const handleTableRowMenuArchive = (id: string) => {
		if (isAuthenticated) {
			entryArchiveMutation(id);
		} else {
			dispatch(entriesUpdateMany([{ id, isArchived: true }]));
		}
		closeTableRowMenu();
	};

	const handleTableRowMenuUnarchive = (id: string) => {
		if (isAuthenticated) {
			entryUnarchiveMutation(id);
		} else {
			dispatch(entriesUpdateMany([{ id, isArchived: false }]));
		}
		closeTableRowMenu();
	};

	const handleTableRowMenuMenuEdit = (id: string) => {
		closeTableRowMenu();
		const repositoryEntry = populatedEntries.find((entry) => entry.id === id);
		if (repositoryEntry) {
			openEditDialog(repositoryEntry);
		}
	};

	const handleTableRowMenuGoTo = (id: string) => {
		closeTableRowMenu();
		navigateToLocationId(id);
	};

	const editDialogTitle = useMemo(() => editFormEntry ? `Edit ${getRepositorySingleUnitLabel(repository).toLowerCase()}?` : '', [editFormEntry, repository]);
	const confirmDeleteDialogTitle = useMemo(() => confirmDeleteDialogEntry ? `Delete ${getRepositorySingleUnitLabel(repository).toLowerCase()}?` : '', [confirmDeleteDialogEntry, repository]);

	const rowMenuOptions: RowMenuOption[] = [
		{
			content: RowMenuOptionGoTo,
			onClick: (item: RowMenuItem) => handleTableRowMenuGoTo(item.id),
			contentProps: {keyboardShortcut: true},
			key: 'goOption',
		},{
			content: RowMenuOptionEdit,
			onClick: (item: RowMenuItem) => handleTableRowMenuMenuEdit(item.id),
			displayCondition: (item: RowMenuItem) => Boolean(!item.isArchived),
			key: 'editOption',
		}, {
			content: RowMenuOptionMoveToTop,
			onClick: RowMenuOptionEffect.MoveToTop,
			displayCondition: (item: RowMenuItem) => Boolean(!item.isArchived),
			key: 'moveToTopOption',
		}, {
			content: RowMenuOptionArchive,
			onClick: (item: RowMenuItem) => handleTableRowMenuArchive(item.id),
			displayCondition: (item: RowMenuItem) => Boolean(!item.isArchived),
			key: 'archiveOption',
		}, {
			content: RowMenuOptionUnarchive,
			onClick: (item: RowMenuItem) => handleTableRowMenuUnarchive(item.id),
			displayCondition: (item: RowMenuItem) => Boolean(item.isArchived),
			key: 'unarchiveOption',
		}, {
			content: RowMenuOptionDelete,
			onClick: (item: RowMenuItem) => handleTableRowMenuDelete(item.id),
			key: 'deleteOption',
		}
	];

	const handleArchiveBtnClick = () => {
		dispatch(showArchivedEntriesToggle());
	};

	return <>
		<Container maxWidth={false} disableGutters={isSmScreen}>
			<EntriesTable
				propertyDefinitions={repositoryPropertyDefinitions}
				data={populatedEntries}
				rowMenuProps={{
					onMenuBtnClick: handleRowMenuBtnClick,
					onClose: closeTableRowMenu,
					anchorEl: menuAnchorEl.current,
					options: rowMenuOptions,
					item: menuEntry,
				}}
				disableReordering={!showArchivedEntries}
				tableFooterContent={<Box display='flex' justifyContent='right'>
					<Button size='small' sx={{color: showArchivedEntries ? 'primary.dark' : 'primary.main'}} onClick={handleArchiveBtnClick}>{showArchivedEntries ? 'view archive' : 'close archive'}</Button>
				</Box>}
			/>
		</Container>
		<AddRepositoryEntryFormDialog
			open={isAddDialogOpen}
			onClose={closeAddDialog}
			onSubmit={handleAddDialogSubmit}
			title={repository.singular ? `New ${repository.singular.toLowerCase()}` : ''}
		>
			<AddRepositoryEntryForm
				onChange={handleAddDialogValueChange}
			/>
		</AddRepositoryEntryFormDialog>
		<EditFormDialog
			open={isEditDialogOpen}
			handleClose={closeEditDialog}
			handleSubmit={onEditFormSubmitEntry}
			title={editDialogTitle}
		>
			<EditEntryForm />
		</EditFormDialog>
		<ConfirmDeleteEntryDialog
			open={isConfirmDeleteDialogOpen}
			handleClose={closeConfirmDeleteDialog}
			handleSubmit={submitConfirmDeleteDialog}
			entry={confirmDeleteDialogEntry}
			title={confirmDeleteDialogTitle}
		/>
	</>;
};

export default RepositoryViewEntries;